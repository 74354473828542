import './App.css';
import StickyFooter from './Article';

function App() {
  return (
    <StickyFooter/>
  );
}

export default App;
