import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import CssBaseline from '@mui/material/CssBaseline';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        huduku
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

export default function StickyFooter() {
  const prefersDarkMode =  true;
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: [
            // 'Ubuntu',
            // 'sans-serif',
            'Gilroy',
            "Open Sans",
            'Reddit Mono',
            'Inconsolata',
            'monospace',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
          'font-variation-settings': '"wdth" 70'
        },
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );
  const boxRef = useRef(null);
  const [dim, setDim] = useState({
    w: boxRef?.current?.clientWidth,
    h: boxRef?.current?.clientHeight
  });

  useEffect(() => {
    if (!boxRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      // radius = buffer + max(h, w)

      setDim({
        w: boxRef?.current?.clientWidth,
        h: boxRef.current.clientHeight
      })
    });
    resizeObserver.observe(boxRef.current);
    return () => resizeObserver.disconnect(); // clean up 
  }, []);

  const buffer = 3;
  const [time, setTime] = useState(Date.now());
  const [count, setCount] = useState(0);
  const pStyle = {
    // paddingBottom: '72px !important',
    margin: '0 0 24px 0'
  }

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => setCount((count + 15) % 360), [time]);
  return (
    <ThemeProvider theme={theme}>
      <Box id="hero"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          alignItems: 'center',
          padding: '0 16px'
        }}
      >
        {/* <img src="../group-3.png" style={{ width: '100%', borderRadius: '10px', marginBottom: '56px', maxHeight: '680px', align: 'center' }} /> */}
        <CssBaseline />
        <Container component="main" sx={{ mt: 8, mb: 2, marginTop: '150px' }} maxWidth="md">
          <div ref={boxRef} style={{ width: `${100}%`, height: `${64}px`, backgroundColor: 'white', borderRadius: '8px', 'mix-blend-mode': prefersDarkMode ? 'overlay' : 'soft-light', display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
          <div style={{ width: `${dim.w - buffer * 2 - 0.5}px`, height: `${dim.h - buffer * 2}px`, margin: `${buffer}px`, marginTop: `-${dim.h - buffer - 0.5}px`, marginLeft: `${buffer + 0.5}px`, borderRadius: '5px', backgroundColor: '#0e0e0e', display: 'flex', alignItems: 'center', padding: '24px' }}>
            <Typography sx={{ letterSpacing: '1.25px', fontWeight: 'bold', transform: 'scale(1.1) translateX(12px)', color: 'rgb(255 90 160 / 76%)' }}>
              huduku ∙ ಹುಡುಕು • We are updating this page soon!
            </Typography>
          </div>
          <div className='radial-linear' style={{ width: `${dim.w}px`, height: `${dim.h}px`, marginTop: `-${dim.h}px`, filter: `blur(8px)`, borderRadius: '8px', position: 'relative', zIndex: -100, display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            {/* <div  style={{ width: '100%', height: '24px', background: 'white', transform: `rotate(${count}deg)`, filter: `blur(8px)`, transition: 'all 0.75s ease' }}></div> */}
          </div>

          <Typography variant="h4" component="h4" gutterBottom sx={{ margin: '72px 0 0 0', fontWeight: '500' }}>
            Building the next Zappier!
          </Typography>
          <Typography variant="h5" component="h4" gutterBottom sx={{ margin: '0 0 48px 0', fontWeight: '500' }}>
            An Agentic and Non-Agentic workflow orchestration platform
          </Typography>
          <Typography variant="p" component="p" sx={{ margin: '0 0 24px 0' }}>
            With the rise of Generative AI and LLMs, user interactions with technology are changing. Chat interfaces as temporary and as AI matures, chat will become less relevant. Today, AI mainly reads and summarizes information, providing concise answers. However, using AI to make decisions and take actions is less common and can be risky if not controlled.
          </Typography>
          <Typography component="p" sx={{ margin: '0 0 24px 0' }}>
            AI can greatly improve enterprise productivity by automating tasks and generating actionable insights. In 2022, while working in fintech, I noticed issues with information decay and smallest of manual tasks like JSON formatting. Employees used third-party sites, risking data security. I built a JSON formatter and added tools like encoders and password generators, improving safety and efficiency.
          </Typography>
          <Typography component="p" sx={{ margin: '0 0 24px 0' }}>
            A major challenge in debugging custom software was switching between tools to find the root cause. This repetitive process can be automated.
          </Typography>
          <Typography component="p" sx={{ margin: '0 0 24px 0' }}>
            Search engines like Google and Perplexity summarize data, solving micro-tasks. However, an <b>"Action Engine"</b> could scan all organizational data, interact with software, and provide actionable CTAs. For example, to plan a team outing, an Action Engine could gather preferences and present a summary, eliminating manual data collection.
          </Typography>
          <Typography component="p" sx={{ margin: '0 0 24px 0' }}>
            The <b>Action Engine</b> would hyper-search within an organization, providing CTAs with essential context from multiple software environments. This would be the primary tool employees use at work.
          </Typography>
          <Typography component="p" sx={{ margin: '0 0 64px 0' }}>
            Currently, products are adapting AI individually, creating inconsistent contexts. Instead, organizations need a unified 1-to-N directed-acyclic-graph (DAG) approach.
          </Typography>
          <Typography variant="h5" component="p" sx={{ margin: '0 0 24px 0' }}>
            What Problem are we solving?
          </Typography>
          <Typography component="p" sx={{ margin: '0 0 112px 0' }}>
            An attempt to improve team productivity with an Action Engine using AI to provide a platform for SaaS products to utilize shared context. Tasks become micro-tasks over time, following Moore's Law. You can't automate an entire task but can automate micro-tasks to make the overall task faster and easier, reducing hops. Building a <b>Workspace</b> for future!
          </Typography>
        </Container>
      </Box>
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 'auto',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[900],
          backgroundImage: `url("./group-3.png")`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',

        }}
      >
        <Container maxWidth="sm">
          <Typography variant="body1">

          </Typography>
          <Copyright />
        </Container>
      </Box>
    </ThemeProvider>
  );
}
